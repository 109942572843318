<template>
	<div class="level-box">
		<img :src="require('@/assets/img/' + name + '.png')" alt="" class="tag" />
	</div>
</template>

<script>
export default {
	name: "TagLvNew",
	props: {
		name: {
			type: String,
			default: "A",
		},
	},
	data() {
		return {};
	},

	mounted() {},

	methods: {},
};
</script>
<style lang="scss" scoped>
.level-box {
	display: inline-block;
}
.tag {
	display: inline-block;
	width: 53px;
	height: 62px;
	text-align: center;
	line-height: 32px;
	border-radius: 3px;
	color: #fff;
	font-weight: 600;
	font-size: 14px !important;
}
.mini {
	width: 40px;
	height: 20px;
	line-height: 20px;
}
// .A {
// 	background-color: #b0ce87;
// }
// .B {
// 	background-color: #4095e5;
// }
// .C {
// 	background-color: #d3b465;
// }
// .D {
// 	background-color: #bd3124;
// }
</style>
