import Vue from "vue";
import AdminTitle from "../components/admin-title/admin-title.vue";
import AdminTitleNew from "../components/admin-title-new/admin-title-new.vue";

import TpTable from "@/components/tp-table/index.vue";
import FileUpload from "@/components/file-upload/index.vue";
import Pagination from "@/components/Pagination/index.vue";
import TagLv from "@/components/tag-lv/tag-lv.vue";
import TagLvNew from "@/components/tag-lv-new/tag-lv-new.vue";
import LevelProgress from "@/components/level-progress/level-progress.vue";

const components = [
	AdminTitle,
	AdminTitleNew,
	TpTable,
	FileUpload,
	Pagination,
	TagLv,
	TagLvNew,
	LevelProgress,
];

export default function install() {
	components.forEach((item) => Vue.component(item.name, item));
}
