<template>
    <card class="level-progress">
        <AdminTitle title="客户评级" size="20px">
            <span class="tip" v-if="scoreProfileData.sysHitSuggestDesc"
                ><i class="el-icon-warning"></i>{{ scoreProfileData.sysHitSuggestDesc }}</span
            >
        </AdminTitle>
        <div class="step-box">
            <div
                class="sys-level"
                v-if="sysScore >= 0"
                :style="{
                    left: sysLeft,
                    backgroundColor: sysColor,
                }"
            >
                <p>系统评级</p>
                <p>评分:{{ sysScore }}</p>
                <i class="el-icon-caret-bottom" :style="{ color: sysColor }"></i>
            </div>
            <div
                class="final-level"
                v-if="finalLevel && showFinal"
                :style="{
                    left: finalLeft + '%',
                    backgroundColor: finalColor,
                }"
            >
                最终评级
                <i class="el-icon-caret-bottom" :style="{ color: finalColor }"></i>
            </div>
            <div
                v-for="(item, index) in levelList"
                :key="index"
                :style="{ backgroundColor: item.color }"
                class="level-item"
            >
                {{ item.name }}级
                <span v-if="!index" class="level-value level-value-0">0</span>
                <span class="level-value">{{ item.maxValue }}</span>
            </div>
        </div>
    </card>
</template>

<script>
export default {
    name: 'LevelProgress',
    props: {
        finalLevel: {
            //最终评级
            type: String,
            default: '', //'A' 'B' 'C' 'D' ''
        },
        sysScore: {
            //系统评级
            type: [Number, String],
            default: 0, // 大于400 小于800
        },
        sysLevel: {
          //最终评级
          type: String,
          default: '', //'A' 'B' 'C' 'D' ''
        },
        scoreProfileData: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        finalLeft() {
            const index = this.levelList.findIndex((i) => i.name == this.finalLevel);
            if (index === -1) {
                return 0;
            }
            return (index * 0.25 + 0.25 / 2) * 100;
        },
        finalColor() {
            const obj = this.levelList.find((i) => i.name == this.finalLevel);
            return obj.color || '';
        },
        showFinal() {
            return this.finalLevel !== this.sysLevel;
        },
        sysLeft() {
            const validSysLevel = Math.max(0, this.sysScore);
            const index = this.levelList.findIndex(
                (i) => i.maxValue >= validSysLevel && i.minValue < validSysLevel
            );
            if (index === -1) {
                return 0;
            }
            const level = this.levelList[index];
            const { minValue, maxValue } = level;
            const diffValue = maxValue - minValue;
            const calcValue = validSysLevel - minValue;
            return `${(0.25 * index + 0.25 * (calcValue / diffValue)) * 100}%`;
        },
        sysColor() {
            const validSysScore = Math.max(0, this.sysScore);
            if (validSysScore == 3000) {
                return '#D05252';
            }
            const obj = this.levelList.find(
                (i) => i.maxValue > validSysScore && i.minValue <= validSysScore
            );
            return obj ? obj.color : '';
        },
    },
    data() {
        return {
            levelList: [
                { name: 'A', maxValue: 1, minValue: 0, color: '#28D576' },
                { name: 'B', maxValue: 100, minValue: 1, color: '#1483EA' },
                { name: 'C', maxValue: 400, minValue: 100, color: '#FFAC46' },
                { name: 'D', maxValue: 3000, minValue: 400, color: '#D05252' },
            ],
        };
    },

    mounted() {},

    methods: {},
};
</script>
<style lang="scss" scoped>
.level-progress /deep/ {
    box-sizing: border-box;
    border-color: transparent;
    margin-top: 20px;
    border-radius: 16px;
    .ivu-card-body {
        display: flex;
        flex-direction: column;
        .final-level,
        .sys-level {
            position: absolute;
            color: #fff;
            font-size: 14px;
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            top: -18px;
            white-space: nowrap;
            transform: translateY(-100%) translateX(-50%);
            border-radius: 10px;
            min-width: 100px;
            .el-icon-caret-bottom {
                position: absolute;
                bottom: -9px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .step-box {
            position: relative;
            margin-top: 80px;
            margin-bottom: 30px;
        }
        > div {
            display: flex;
            justify-content: space-between;
        }
        .level-item {
            display: flex;
            color: #fff;
            width: 25%;
            margin-right: 2px;
            height: 28px;
            justify-content: center;
            size: 16px;
            align-items: center;
            position: relative;
            &:last-child {
                margin-right: 0;
                .level-value {
                    right: 14px;
                }
            }
            .level-value {
                position: absolute;
                bottom: 0;
                right: 0;
                transform: translate(50%, 120%);
                color: #1d2129;
            }
            .level-value-0 {
                left: 0;
                right: initial;
                transform: translate(0, 120%);
            }
        }
        .tip {
            color: #fff;
            font-size: 12px;
            padding: 4px 12px;
            border-radius: 20px;
            background: #bd3124;
            position: absolute;
            left: 100px;
            top: 2px;
            .el-icon-warning {
                color: #fff;
                margin-right: 5px;
            }
        }
    }
}
</style>
