// https://www.jianshu.com/p/634248653b32

let OSS = require('ali-oss')

//client配置
let client = null;

export default (conf) => {
    return new OSS(conf)
};
//文件上传
export const put = async (filePath, fileUrl) => {
    try {
        let result = await client.put(filePath, fileUrl)
        return result
    } catch (e) {
        console.log(e)
    }
}

//oss的地址
export const getSystemPath = () => {
    // return ossPath
}

//文件的上传地址
export const getFilePath = (ObjName, orangeName) => {
    let fileName = getFileNumber() + getFileSuffix(orangeName)
    let result = `/upload/${fileName}`
    return result
}

//oss文件名获取随机
export const getFileNumber = () => {
    let timeNumber = new Date().getTime()
    let randomNumber = Math.floor(Math.random() * 999999)
    return timeNumber + '' + randomNumber
}

//获取文件后缀
export const getFileSuffix = (fileName) => {
    let name = fileName.lastIndexOf('.')//取到文件名开始到最后一个点的长度
    let length = fileName.length
    let fileSuffix = fileName.substring(name, length)
    return fileSuffix

}

//检查文件格式
export const checkFileFormat = (fileName) => {
    let isJPG = false
    let type = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
    if (type === 'jpg' || type === 'png' || type === 'jpeg') {
        isJPG = true
    } else if (type === 'mp4' || type === '3gp' || type === 'avi') {
        isJPG = true
    }
    return isJPG
}

//获取录音和视频的时长
export const getTimeFromVideo = (file) => {
    let time = 0
    var url = URL.createObjectURL(file)
    var audioElement = new Audio(url)
    audioElement.addEventListener('loadedmetadata', (_event) => {
        time = parseInt(audioElement.duration)
        return time
    })
    console.log(time)
}
