<template>
	<div id="app">
		<a-layout v-if="!isLoginPage">
			<a-layout-sider v-model="collapsed" :trigger="null" collapsible>
				<left-menu></left-menu>
			</a-layout-sider>
			<a-layout>
				<a-layout-header style="background: #fff; padding: 0">
					<top-menu v-model="collapsed"></top-menu>
				</a-layout-header>
				<div>
					<!-- <TagsView></TagsView> -->
				</div>

				<a-layout>
					<a-layout-sider
						:style="{
							marginBottom: '24px',
							background: '#fff',
							paddingTop: '24px',
							maxWidth: '140px',
							minWidth: '0',
							flex: 'unset',
							width: 'auto',
						}"
					>
						<!-- <left-second-menu></left-second-menu> -->
					</a-layout-sider>
					<a-layout-content
						:style="{
							margin: '24px 16px',
							padding: '24px',
							background: '#fff',
							display: 'flex',
							width: '800px',
						}"
						:class="{ 'without-layout': withoutLayout }"
					>
						<!-- <keep-alive> -->
						<router-view v-if="isRouterAlive"></router-view>
						<!-- </keep-alive> -->
					</a-layout-content>
				</a-layout>
			</a-layout>
		</a-layout>
		<router-view v-else></router-view>
	</div>
</template>
<script>
import LeftMenu from "./components/left-menu";
import TopMenu from "./components/top-menu";
import LeftSecondMenu from "./components/left-second-menu";
import TagsView from "@/components/TagsView/index.vue";

const WITH_OUT_LAYOUT_ROUTE_NAME = ["ReviewDetail", "OrderDetail"];

export default {
	data() {
		return {
			collapsed: false,
			isRouterAlive: true,
		};
	},
	provide() {
		return {
			reload: this.reload,
		};
	},
	computed: {
		isLoginPage() {
			return this.$route.path === "/login";
		},
		withoutLayout() {
			return (
				WITH_OUT_LAYOUT_ROUTE_NAME.includes(this.$route.name)
			);
		},
	},

	components: {
		LeftMenu,
		TopMenu,
		// TagsView
	},
	mounted() {},
	watch: {
		$route: {
			handler(newR, oldR) {
				if (newR.name == "OrderDetail" || newR.name == "ReviewDetail") {
					document.querySelector(".ant-layout-content").scrollTo(0, 0);
				}
			},
		},
	},
	methods: {
		reload() {
			this.isRouterAlive = false;
			this.$nextTick(() => {
				this.isRouterAlive = true;
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.ant-layout {
	height: 100vh;
	overflow: hidden;
}

.ant-layout-content {
	overflow-y: auto;
	// &::-webkit-scrollbar {
	//   width: 2px;
	//   background-color: #ccc;
	// }
}
.without-layout {
	background: transparent !important;
	overflow: hidden;
	padding: 24px 0 !important;
	margin: 24px 0 !important;
}

.container {
	display: flex;
}
</style>
