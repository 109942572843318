<template>
  <div class="level-box">
    <div v-if="name == 'A'" class="tag a" :class="{ mini: size == 'mini' }">A</div>
    <div v-if="name == 'B'" class="tag b" :class="{ mini: size == 'mini' }">B</div>
    <div v-if="name == 'C'" class="tag c" :class="{ mini: size == 'mini' }">C</div>
    <div v-if="name == 'D'" class="tag d" :class="{ mini: size == 'mini' }">D</div>
  </div>
</template>

<script>
export default {
  name: 'TagLv',
  props: {
    name: {
      type: String,
      default: 'A'
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.level-box {
  display: inline-block;
}
.tag {
  display: inline-block;
  width: 60px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  font-size: 14px!important;
}
.mini{
  width: 40px;
  height: 20px;
  line-height: 20px;
}
.a {
  background-color: #b0ce87;
}
.b {
  background-color: #4095e5;
}
.c {
  background-color: #d3b465;
}
.d {
  background-color: #bd3124;
}
</style>
