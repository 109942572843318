<template>
  <div class="top-menu">
    <div class="left">
      <a-icon class="icon" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="changeCollapsed" />
      <a-icon class="icon" type="reload" @click="refresh" />
      <!-- <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for=" item in bread" :key="item.path" :to="{ path:item.path }">{{item.name}}</el-breadcrumb-item>
        
      </el-breadcrumb> -->
    </div>
    <div class="right">
      <span>欢迎您：{{ name }}</span>
      <div class="logout" @click="logout">
        <a-icon class="icon" type="logout" />
        退出登录
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TopMenu',
  model: {
    prop: 'collapsed'
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      userName: ''
    }
  },

  created() {
    this.userName = localStorage.getItem('userName')
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    }),
    ...mapState({ name: state => state.user.name }),
    bread(){
      let breadList = []
     breadList = this.$route.matched.map(item => {
          return {
            path: item.path,
            name: item.meta.name
          }
        })
      return breadList
    }
  },

  methods: {
    changeCollapsed() {
      this.$emit('input', !this.collapsed)
      this.$bus.$emit('showLogo')
    },

    refresh() {
      history.go(0)
    },

    logout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push('/login')
        // this.$store.dispatch('tagsView/delAllViews')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;

  .icon {
    margin-right: 20px;
    font-size: 16px;
  }

  .right {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 20px;
      margin-right: 0;
    }

    .logout {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .left{
    display: flex;
    align-items: center;
  }
}
</style>
