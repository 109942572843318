<template>
	<div :style="{ fontSize: size, marginBottom: bottom }" class="title">
		{{ title }}
	</div>
</template>

<script>
export default {
	name: "AdminTitleNew",
	props: {
		size: {
			type: String,
			default: "24px",
		},
		title: {
			type: String,
			default: "",
		},
		bottom: {
			type: String,
			default: "20px",
		},
	},
};
</script>

<style lang="scss" scoped>
.title {
	position: relative;
	color: #222222;
	font-weight: 400;
	line-height: 33.6px;
}
.without-layout .title {
	padding: 0 14% !important;
}
.title::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 0px;
	transform: translateY(-50%);
	width: 4px;
	height: 12px;
}
</style>
