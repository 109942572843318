export default {
  state: {
    rate: '',
    periodList: []
  },
  mutations: {
    UPDATE_RATE(state, rate) {
      state.rate = rate
    },
    UPDATE_PERIOD(state, periodList) {
      state.periodList = periodList
    }
  },
  actions: {}
}
